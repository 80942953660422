@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400&display=swap');

.HP-font {
  font-size: 3.5em; 
}

html, body{
  height: 100%;
  margin: 0px;
}

body {
  height: 100%;
  width: 100%;
  background-image:  linear-gradient(rgba(255, 255, 255, 0.75),rgba(226, 226, 226, 0.88)) ,url("./images/hogwarts.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
}

.centered-title {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  padding: 30px;
}

.centered {
  width: 100vw;
  position: fixed;
  top: 40%;
  left: 50%;
  height: fit-content;
  transform: translate(-50%, -50%);
}

.flex-container {
  display: flex;
  flex-direction: row;
  max-height: 50vh;
}

.flex-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.flex-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.box {
  flex: 1;
  margin: 0 25px;
}
/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .flex-container {
    overflow-y: scroll;
    flex-direction: column;
  }
}

.Hufflepuff {
  color: #B49134;
}

.Ravenclaw {
  color: #2A4875;
}

.Slytherin {
  color: #2A6439;
}

* {
  font-family: 'Nunito', sans-serif;
  font-size: 1.05em;
}
ul, li {
  all: unset;
}

.goal-list {
  display: flex;
  align-items: baseline;
  margin-top: 10vh;
}
li {
  display: block;
  background-color: rgba( 255, 255, 255, 0.05 );
  box-shadow: 0 8px 32px 0 rgba(85, 85, 85, 0.20);
  backdrop-filter: blur( 2px );
  -webkit-backdrop-filter: blur( 2px );
  border-radius: 10px;
  /* border: 1px solid rgba( 255, 255, 255, 0.18 );   */
  min-height: 50px;
  margin-block: 15px;
  align-items: center;
  border-radius: 5px;
}

.check {
  background-color: rgba(30, 162, 23, 0.693);
}

li {
  display: flex;
}

li .step {
  min-width: 100px;
  height: 100%;
  position: relative;
  text-align: center;
  font-weight: 400;
}
li .description {
  flex-grow: 1;
  padding: 5px 5px 5px 5px;
  font-weight: 200;
  text-align: left;
}

.footer {
    display:block; 
    float:left; 
}

.imgFooter {
  margin: 5%;
  margin-top: -5%;
}